<template>
  <div>
    <div class="min-h-50 d-flex w-100 align-items-center justify-content-center" v-if="!hasSnapshot && !loading">
      <div class="text-center">
        <b-icon icon="shield" class="h1" />
        <p class="my-3">
          Ainda não há informações de Seguros cobertura de riscos sincronizadas.
        </p>
        <b-button v-b-modal.select-insurances v-if="is_provider && enabled">
          <b-icon icon="arrow-down-up" class="mr-2" />
          <span>Sincronizar agora</span>
        </b-button>
      </div>
    </div>
    <div v-else>
      <div class="d-flex align-items-center justify-content-start mb-3">
        <small class="mr-2">
          Ultima atualização {{ insurance.updated_at | dateFull }}</small>
        <unicon v-if="is_provider && enabled" name="redo" v-b-modal.select-insurances class="pointer small"
          style="font-size: 12px" height="16" width="16" />
      </div>
      <cards-list grid="2" responsive="1">
        <b-card no-body class="card-security p-2" v-for="(ins, index) in insurance.data" :key="index">
          <div class="mr-md-4">
            <span class="d-block title" v-if="ins && ins.insurance && ins.insurance.insurance_company">
              {{ ins.insurance.insurance_company.social_reason }}
            </span>
            <small class="text-muted">{{ ins.situation }}</small>
          </div>
          <hr />
          <div>
            <table class="mb-3">
              <tr>
                <td><strong>Término da vigência:</strong></td>
                <td class="pl-2">{{ ins.validity | date }}</td>
              </tr>
              <tr>
                <td><strong>Cobertura:</strong></td>
                <td class="pl-2">{{ ins.coverage | money }}</td>
              </tr>
              <tr>
                <td><strong>Tipo:</strong></td>
                <td class="pl-2">{{ ins.type }}</td>
              </tr>
            </table>
          </div>
        </b-card>
      </cards-list>
    </div>

    <b-modal id="select-insurances" size="lg" centered scrollable hide-header hide-footer>
      <template #modal-header> </template>
      <div class="py-3">
        <ModalSelect @close="handleSetSelecteds" />
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ModalSelect from "./ModalSelect.vue";

export default {
  components: {
    ModalSelect,
  },
  data() {
    return {
      loading: true,
      enabled: true,
      search: "",
      selecteds: [],
      insurance: {},
      hasSnapshot: false,
      service_id: "",
      provider_id: "",
      homologationId: "",
    };
  },

  computed: {
    ...mapGetters(["is_preview", "preview_personify", "user", "is_provider"]),
  },
  methods: {
    ...mapActions(["set_snapshot", "get_snapshot", "show_loading"]),

    getSnapshot() {
      this.loading = true;
      this.show_loading({
        loading: true,
      });
      this.get_snapshot({
        type: "insurance-coverage",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then((response) => {
        this.enabled = response.enabled;
        this.$bvModal.hide("modal-generating-snapshot");
        if (!response?.created_at) {
          this.hasSnapshot = false;
        } else {
          this.hasSnapshot = true;
          this.insurance = response;
        }
        this.show_loading({
          loading: false,
        });
        this.loading = false;
      });
    },

    handleSetSelecteds(selecteds) {
      this.$bvModal.show("modal-generating-snapshot");
      this.$bvModal.hide("select-insurances");
      if (selecteds.length === 0) {
        return;
      }
      this.set_snapshot({
        type: "insurance-coverage",
        service_id: this.service_id,
        provider_id: this.provider_id,
        data: selecteds.flatMap(({ id }) => [id]),
      }).then(() => {
        this.$bvModal.hide("modal-generating-snapshot");
        this.hasSnapshot = true;
        this.getSnapshot();
      });
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id || this.$route.query.provider_id;
    this.homologationId = this.$route.params.homologation_id;
    this.getSnapshot();
  },
};
</script>
<style lang="scss">
.content-security {
  margin: 0 -10px;

  .card-security {
    width: calc(19% + 51px);
    display: inline-grid;
    margin: 0 10px;

    &.disabled {
      opacity: 0.4;
      filter: grayscale(5);
      cursor: not-allowed;
      pointer-events: none;
      user-select: none;
    }

    &.selected {
      border-color: $primary;

      .title {
        color: $primary;
      }
    }
  }
}
</style>
